var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.container},[_c('Search',{class:_vm.$style.search,attrs:{"items":_vm.searchProducts,"is-show":_vm.isShow},on:{"clear":_vm.handleClearSearch,"submit":_vm.submitSearch,"click":_vm.submitSearch},model:{value:(_vm.searchItem),callback:function ($$v) {_vm.searchItem=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchItem"}},_vm._l((_vm.searchProducts),function(item,index){return _c('div',{key:index,class:_vm.$style.item},[_c('router-link',{class:_vm.$style.link,attrs:{"to":_vm.getRoute({
            route: _vm.$options.ADDSELLERS_ADMIN_ROUTES.ORDERS.ADDSELLERS.VIEW,
            params: { id: item.id },
          })}},[_c('span',[_vm._v("Номер"),_c('br'),_vm._v(_vm._s(item.name))]),_c('span',[_vm._v("Оформлен"),_c('br'),_vm._v(_vm._s(_vm.fomateDateRow(item.createdAt)))]),_c('span',[_vm._v("Цена"),_c('br'),_vm._v(_vm._s(item.price))]),_c('span',[_vm._v("Дата доставки"),_c('br'),_vm._v(_vm._s(_vm.fomateDateRow(item.deliveryAt)))]),_c('span',[_vm._v("Склад"),_c('br'),_vm._v(_vm._s(item.warehouse))]),_c('span',[_vm._v("Получатель"),_c('br'),_vm._v(_vm._s(item.recipientRequesite))])])],1)}),0),_c('vue-good-table',{class:_vm.$style.table,attrs:{"columns":_vm.columns,"rows":_vm.list,"styleClass":"vgt-table striped"},on:{"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'createdAt')?_c('span',[_vm._v(" "+_vm._s(_vm.fomateDateRow(props.row.createdAt))+" ")]):(props.column.field === 'deliveryAt')?_c('span',[_vm._v(" "+_vm._s(_vm.fomateDateRow(props.row.deliveryAt))+" ")]):(props.column.field === 'warehouse')?_c('span',[_vm._v(" "+_vm._s(_vm.formatWarehouse(props.row.warehouse))+" ")]):(props.column.field === 'createdManager')?_c('router-link',{attrs:{"to":_vm.getRoute({
            route: _vm.$options.MANAGERS_ROUTES.UPDATE,
            params: { id: props.row.createdManagerId },
          })}},[_vm._v(" "+_vm._s(props.row.createdManager !== null ? props.row.createdManager.name : '')+" ")]):(props.column.field === 'status')?_c('span',{class:[_vm.$style.statusOrder, _vm.$style[_vm.getStatus(props.row.status)]]},[_vm._v(" "+_vm._s(_vm.$options.ORDER_STATE_TYPES_SELLERS_DISPLAY[props.row.status])+" ")]):(props.column.field === 'action')?_c('span',{class:_vm.$style.actions},[_c('ActionButtons',{class:_vm.$style.actionsBtn,attrs:{"edit-link":_vm.getRoute({
              route:
                _vm.$options.ADDSELLERS_ADMIN_ROUTES.ORDERS.ADDSELLERS.UPDATE,
              params: { id: props.row.id },
            }),"view-link":_vm.getRoute({
              route: _vm.$options.ADDSELLERS_ADMIN_ROUTES.ORDERS.ADDSELLERS.VIEW,
              params: { id: props.row.id },
            })},on:{"delete":function($event){return _vm.deleteOrder(props.row)}}})],1):_vm._e()]}}])}),_c('Pagination',{attrs:{"curPage":_vm.query.page,"lastPage":_vm.pageCount},on:{"go-next":function($event){return _vm.goNext()},"go-prev":function($event){return _vm.goPrev()}}}),_c('Loader',{attrs:{"is-loading":_vm.isLoading}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }